import { Cache, CacheSelector } from '../cache';
import addPageAction from './add-page-action';

interface UserProfile {
  upmId: string | undefined;
  registeredCountry: string | undefined;
  signInFlow: string | undefined;
  userType: string;
}

interface UserProfileWithSwoosh extends UserProfile {
  isSwoosh: boolean;
}

interface Identity {
  getUserProfile: () => Promise<UserProfile | undefined>;
  getIsSwooshUser: () => Promise<boolean>;
}

// NOTE we will be back to update more data from userProfile vs unite
export default async function readAndUpdateCachedUserData(
  cache: Cache,
  identity: Identity
): Promise<UserProfileWithSwoosh> {
  let userProfile: UserProfileWithSwoosh = {
    isSwoosh: false,
    registeredCountry: undefined,
    signInFlow: undefined,
    upmId: undefined,
    userType: ''
  };
  try {
    // NOTE we will use an already required value as the check vs creating more variables than necessary at runtime
    const cachedUserType = cache.get(
      CacheSelector.WSC_IDENTITY_USER_TYPE
    ) as string;
    if (cachedUserType === 'GUEST') {
      userProfile.userType = cachedUserType;
    } else if (cachedUserType) {
      userProfile.registeredCountry = cache.get(
        CacheSelector.WSC_IDENTITY_REGISTERED_COUNTRY
      ) as string | undefined;
      userProfile.signInFlow = cache.get(
        CacheSelector.WSC_IDENTITY_SIGN_IN_FLOW
      ) as string | undefined;
      userProfile.upmId = cache.get(CacheSelector.WSC_IDENTITY_UPM_ID) as
        | string
        | undefined;
      userProfile.userType = cachedUserType;
      userProfile.isSwoosh = cache.get(
        CacheSelector.WSC_IDENTITY_IS_SWOOSH
      ) as boolean;
    } else {
      const identityUserProfile = await identity.getUserProfile();
      // handle swoosh as well
      if (identityUserProfile) {
        const isSwoosh = await identity.getIsSwooshUser();
        userProfile = { ...identityUserProfile, isSwoosh };
        // prioritize required values
        cache.set(CacheSelector.WSC_IDENTITY_UPM_ID, userProfile.upmId);
        cache.set(CacheSelector.WSC_IDENTITY_USER_TYPE, userProfile.userType);
        // handle optional values
        cache.set(
          CacheSelector.WSC_IDENTITY_REGISTERED_COUNTRY,
          userProfile.registeredCountry
        );
        cache.set(
          CacheSelector.WSC_IDENTITY_SIGN_IN_FLOW,
          userProfile.signInFlow
        );
        // handle swoosh as well
        cache.set(CacheSelector.WSC_IDENTITY_IS_SWOOSH, userProfile.isSwoosh);
      }
    }

    return userProfile;
  } catch (error) {
    // NOTE we just need to track this initially to see if there are scenarios that we don't know about
    // but we also don't want to use noticeError as it will spike other apps' error rates inadvertently
    // NOTE prior to typescript 4.0 error in try/catch was unknown.. so we'll ignore ts for now
    addPageAction('DCAC_ERROR', {
      // @ts-expect-error
      errorMessage: error.message,
      // @ts-expect-error
      stackTrace: error.stack
    });

    return userProfile;
  }
}

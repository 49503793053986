import { Md5 } from './lib/md5';

const generateMessageId = (): string => {
  const randomString = Math.random().toString();
  const hashId = Md5.hashStr(new Date().toISOString() + randomString);

  return `ajs-${hashId}`;
};

export { generateMessageId };

import { ABTest, RawEvent } from './analytics-event.d';
import { Cache, CacheSelector } from '../cache';

const getABTest = (_rawEvent: RawEvent, cache: Cache): ABTest => {
  const adobeTargetId = cache.get(CacheSelector.MBOX);

  return {
    adobeTargetId: typeof adobeTargetId === 'string' ? adobeTargetId : undefined
  };
};

export { getABTest };

import { logger } from './logger';
// postBeaconRequestToChromeSpyExtension is a shim
// due to chromes poor support for Blob in sendBeacon
// see bug report: https://bugs.chromium.org/p/chromium/issues/detail?id=930685
// TODO: remove this functionality once chrome gets its act together
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore next */
const postBeaconRequestToChromeSpyExtension = (
  endpoint: string,
  payload: object
): void => {
  // is it chrome?
  // https://stackoverflow.com/a/9851769
  if (
    window &&
    (<any>window).chrome &&
    ((<any>window).chrome.webstore || (<any>window).chrome.runtime) &&
    window.postMessage
  ) {
    const spiedEvent = {
      request: { data: payload, url: endpoint },
      type: 'SPIED_EVENT'
    };
    window.postMessage(spiedEvent, '*');
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const logAttempt = (
  type: 'beacon' | 'fetch',
  endpoint: string,
  payload: object
): void => {
  const msg = `send attempt using '${type}' to '${endpoint}'`;
  logger.info(msg, payload);
};

const canUseBeaconApi = (): boolean =>
  Boolean(navigator && navigator.sendBeacon);

const sendFetch = (endpoint: string, payload: object): void => {
  const data = JSON.stringify(payload);

  fetch(endpoint, {
    body: data,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  });
};

const sendBeacon = (endpoint: string, payload: object): void => {
  const data = new Blob([JSON.stringify(payload)], {
    type: 'application/json'
  });

  if (navigator.sendBeacon(endpoint, data)) {
    postBeaconRequestToChromeSpyExtension(endpoint, payload);
  } else {
    logAttempt('fetch', endpoint, payload);
    sendFetch(endpoint, payload);
  }
};

type Payload = {
  type: string;
};
const send = <T>(payload: T & Payload, mustUseFetch: boolean): void => {
  try {
    const endpoint = 'https://analytics.nike.com/v1';
    const path = payload.type.slice(0, 1);
    if (canUseBeaconApi() && !mustUseFetch) {
      logAttempt('beacon', `${endpoint}/${path}`, payload);
      sendBeacon(`${endpoint}/${path}`, payload);

      return;
    }

    logAttempt('fetch', `${endpoint}/${path}`, payload);
    sendFetch(`${endpoint}/${path}`, payload);
  } catch (err) {
    const msg =
      err && err.message && typeof err.message === 'string'
        ? err.message
        : 'unknown';
    if (msg.indexOf('sendBeacon') > -1) {
      // attempt to send via fetch
      send(payload, true);

      return;
    }
    throw err;
  }
};

export { send };

import { Cookies } from '../../lib/cookie';
import { logger } from '../../logger';

const getMbox = (cookies: Cookies): (() => string | undefined) => ():
  | string
  | undefined => {
  const mboxValue: string = cookies.get('mbox');
  let mbox: string | undefined;

  if (mboxValue) {
    const [mboxOne] = mboxValue.split('|');
    if (mboxOne) {
      const matches = mboxOne.match(/^PC#(.*)\./);
      if (matches && Array.isArray(matches) && matches.length > 1) {
        const [, one] = matches;
        mbox = one;
      }
    }
  }
  logger.debug('Mbox:', mboxValue);
  logger.debug('mbox:', mbox as string);

  return mbox;
};

export { getMbox };

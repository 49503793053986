import { Cache, CacheSelector } from '../cache';
import { Properties, RawEvent } from './analytics-event.d';
import { Cookies } from '../lib/cookie';
import addPageAction from '../initialize/add-page-action';
import { getABTest } from './abtest';
import { getCP } from './cp';
import { getConsumer } from './consumer';
import { getContent } from './content';
import { getPreviousView } from './previous-view';
import getWebShellClientIdentity from '../get-web-shell-client-identity';
import readAndUpdateCachedUserData from '../initialize/read-and-update-cached-user-data';

const getProperties = async (
  rawEvent: RawEvent,
  cache: Cache,
  cookies: Cookies
): Promise<Properties> => {
  const { name, type: eventType } = rawEvent;
  const language = cache.get(CacheSelector.GLOBAL_BROWSER_LANGUAGE) as string;
  const abTest = getABTest(rawEvent, cache);
  const classification = 'experience event';
  const consumer = await getConsumer(rawEvent, cache);
  const content = getContent(rawEvent);
  const { cpCode, cpCodeReferrer } = getCP(cache);
  const eventName = eventType === 'page' ? 'Page Viewed' : name;
  const previousView = getPreviousView(rawEvent, cache, cookies);

  let country;
  const identity = getWebShellClientIdentity();

  if (identity) {
    const userProfile = await readAndUpdateCachedUserData(cache, identity);
    if (userProfile) {
      country = userProfile.registeredCountry as string;
    }
  } else {
    const { anonymousId } = consumer;
    addPageAction('DCAC_MISSING_WSC_IDENTITY', { anonymousId });
  }

  const wrapper = {
    build: PRODUCTION ? 'production' : 'development',
    version: VERSION
  };

  return {
    abTest,
    classification,
    consumer,
    content,
    country,
    cpCode,
    cpCodeReferrer,
    eventName,
    eventType,
    language,
    previousView,
    wrapper
  };
};

export { getProperties };

import { Cookies } from './lib/cookie';
import { logger } from './logger';
import { makeAPI } from './api';

const api = makeAPI(window.MOBILE_TARGET_PLATFORM);

/* eslint-disable @typescript-eslint/no-explicit-any */
type WrappedName = 'debug' | 'load' | 'page' | 'timeout' | 'track';
type WrappedFn = (...args: any[]) => void;

const wrapFn = (name: WrappedName): WrappedFn => (...args: any[]): void => {
  try {
    logger.warn(
      'DEPRECATION WARNING: The "segment" namespace has been deprecated. Please use the "analyticsClient" namespace.'
    );
    (<any>api[name])(...args);
  } catch (err) {
    // do nothing
  }
};

const segmentObj = {
  debug: wrapFn('debug'),
  load: wrapFn('load'),
  page: wrapFn('page'),
  timeout: wrapFn('timeout'),
  track: wrapFn('track')
};

// needed for akamai BOOMR to do metrics
const anonymousId = (): string | null =>
  new Cookies().get('anonymousId') || null;

if (!(window as any).segment && !(window as any).analyticsClient) {
  (window as any).segment = { ...segmentObj };
  (window as any).analyticsClient = {
    ...api,
    get _anonymousId(): string | null {
      return anonymousId();
    }
  };
}
/* eslint-enable @typescript-eslint/no-explicit-any */

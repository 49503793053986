import { Cache, CacheSelector } from '../../cache';
import { Cookies } from '../../lib/cookie';
import { PrivacyError } from '../../privacy-error';
import { initializeLocale } from './locale';
import { initializePrivacyPreferences } from './privacy-preferences';

const cookies = new Cookies();

const initPrivacy = async (cache: Cache): Promise<void> => {
  try {
    const countryCode = cache.get(CacheSelector.COUNTRY_CODE)
      ? (cache.get(CacheSelector.COUNTRY_CODE) as string)
      : initializeLocale(cache, cookies).getCountryCode();
    const privacyPreferences = initializePrivacyPreferences(
      cookies,
      countryCode
    );

    // Set the country code regardless of where it was sourced.
    if (typeof countryCode === 'string') {
      // If the country code was sourced from the URL it could be lowercase, so uppercase it!
      cache.set(CacheSelector.COUNTRY_CODE, countryCode.toUpperCase());
    }

    await privacyPreferences.ready();

    const {
      allowMarketing,
      allowPerformance
    } = privacyPreferences.getPreferences();

    const cacheAllowsMarketing = cache.get(
      CacheSelector.PRIVACY_ALLOW_MARKETING
    ) as boolean;
    const cacheAllowsPerformance = cache.get(
      CacheSelector.PRIVACY_ALLOW_PERFORMANCE
    ) as boolean;

    if (cacheAllowsMarketing === undefined) {
      cache.set(CacheSelector.PRIVACY_ALLOW_MARKETING, allowMarketing);
    } else {
      cache.set(
        CacheSelector.PRIVACY_ALLOW_MARKETING,
        allowMarketing && cacheAllowsMarketing
      );
    }

    if (cacheAllowsPerformance === undefined) {
      cache.set(CacheSelector.PRIVACY_ALLOW_PERFORMANCE, allowPerformance);
    } else {
      cache.set(
        CacheSelector.PRIVACY_ALLOW_PERFORMANCE,
        allowPerformance && cacheAllowsPerformance
      );
    }
  } catch (err) {
    const msg = (err && err.message) || 'Unknown privacy error';
    throw new PrivacyError(msg);
  }
};

export { initPrivacy };

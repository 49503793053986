import { sendInsights } from './insights';

type Counter = {
  inc(msg?: string): void;
};

const newCounter = (name: string): Counter => {
  const inc = (msg?: string): void => {
    sendInsights(name, msg);
  };

  return { inc };
};

const metrics = {
  anonymousIdValueImproperlySet: newCounter(
    'anonymous_id_value_improperly_set'
  ),
  eventProcessingFailed: newCounter('event_processing_failed'),
  loaded: newCounter('loaded'),
  loadedMoreThanOnce: newCounter('loaded_more_than_once'),
  loadingFailed: newCounter('loading_failed'),
  pageEventDropped: newCounter('page_event_dropped'),
  pageEventSent: newCounter('page_event_sent'),
  trackEventDropped: newCounter('track_event_dropped'),
  trackEventSent: newCounter('track_event_sent')
};

export { metrics };

import { getDebugMode } from '../debug';

export enum LoggerLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  OFF
}

const CSS_DEBUG_LABEL = 'color:white;background-color:green';
const CSS_INFO_LABEL = 'color:white;background-color:blue';
const CSS_WARN_LABEL = 'color:white;background-color:magenta';
const CSS_ERROR_LABEL = 'color:white;background-color:red';

const DEFAULT_LEVEL = ((): LoggerLevel => {
  const l = LoggerLevel[LoggerLevel[getDebugMode()]];
  if (l !== 'undefined') {
    return LoggerLevel[getDebugMode()];
  }

  return LoggerLevel.ERROR;
})();

let level: LoggerLevel = DEFAULT_LEVEL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (methodLevel: LoggerLevel, ...args: any[]): void => {
  if (methodLevel < level) {
    return;
  }

  switch (methodLevel) {
    case LoggerLevel.DEBUG:
      // eslint-disable-next-line no-console
      console.log(
        `%c${PACKAGE}@v${VERSION} - Debug:`,
        CSS_DEBUG_LABEL,
        ...args
      );
      break;
    case LoggerLevel.INFO:
      // eslint-disable-next-line no-console
      console.info(`%c${PACKAGE}@v${VERSION} - Info:`, CSS_INFO_LABEL, ...args);
      break;
    case LoggerLevel.WARN:
      // eslint-disable-next-line no-console
      console.warn(`%c${PACKAGE}@v${VERSION} - Warn:`, CSS_WARN_LABEL, ...args);
      break;
    case LoggerLevel.ERROR:
      // eslint-disable-next-line no-console
      console.error(
        `%c${PACKAGE}@v${VERSION} - Error:`,
        CSS_ERROR_LABEL,
        ...args
      );
      break;
    case LoggerLevel.OFF:
    default:
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debug = (...args: any[]): void => {
  log(LoggerLevel.DEBUG, ...args);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const error = (...args: any[]): void => {
  log(LoggerLevel.ERROR, ...args);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const info = (...args: any[]): void => {
  log(LoggerLevel.INFO, ...args);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const warn = (...args: any[]): void => {
  log(LoggerLevel.WARN, ...args);
};

const getLevel = (): LoggerLevel => level;
const setLevel = (l: LoggerLevel): void => {
  level = l;
};

const logger = {
  debug,
  error,
  getLevel,
  info,
  setLevel,
  warn
};

export { logger };
